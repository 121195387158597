// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

// ORDER
export const GET_ORDERS = 'GET_ORDERS'
export const IMPORT_ORDER = 'IMPORT_ORDER'
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_LOGS = 'GET_ORDER_LOGS'
export const GET_ORDER_STEPPER = 'GET_ORDER_STEPPER'
export const GET_ORDER_INTERVENTIONS = 'GET_ORDER_INTERVENTIONS'
export const FIX_ORDER_INTERVENTION = 'FIX_ORDER_INTERVENTION'

export const SAVE_PAYMENT_DATA = 'SAVE_PAYMENT_DATA'
export const SEND_RECEIPT = 'SEND_RECEIPT'
export const CREATE_AUTOMATIC_RECEIPT = 'CREATE_AUTOMATIC_RECEIPT'
export const MANUAL_RECEIPT = 'MANUAL_RECEIPT'

export const GET_REPORT = 'GET_REPORT'
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'
