import API from './axiosConfig'
import { BU, APPLICATION, ITEMS_TYPE } from './constants'

export default {
  user: {
    authenticate: token =>
      API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  order: {
    list: (storeId, type) =>
      API.get('/list', {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    receiptTodoList: () =>
      API.get('/failedReceiptList', {
        params: { bu: BU, application: APPLICATION }
      }),
    import: (orderCode, storeId, log) =>
      API.post(
        '/import',
        { itemCode: orderCode },
        {
          params: {
            bu: BU,
            application: APPLICATION,
            storeId,
            log,
            type: ITEMS_TYPE
          }
        }
      ),
    get: (number, storeId) =>
      API.get(`/item/${number}`, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    logs: (number, storeId) =>
      API.get(`/item/${number}/logs`, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    stepper: (number, storeId) =>
      API.get(`/item/${number}/stepper`, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    interventions: (number, storeId) =>
      API.get(`/item/${number}/interventions`, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    fixIntervention: (interventionId, number, storeId) =>
      API.post(
        `/item/${number}/fixIntervention`,
        { interventionId },
        {
          params: {
            bu: BU,
            application: APPLICATION,
            storeId,
            type: ITEMS_TYPE
          }
        }
      ),
    savePaymentData: (body, number, storeId) =>
      API.post(`/item/${number}/ecommercePayment`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    sendReceipt: (body, number, storeId) =>
      API.post(`/item/${number}/sendReceipt`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      }),
    createAutomaticReceipt: (number, storeId) =>
      API.post(
        `/item/${number}/createReceipt`,
        {},
        {
          params: {
            bu: BU,
            application: APPLICATION,
            storeId,
            type: ITEMS_TYPE
          }
        }
      ),
    manualReceipt: (body, number, storeId) =>
      API.post(`/item/${number}/manualReceipt`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type: ITEMS_TYPE }
      })
  },
  report: {
    get: () => API.get('/report/accounting'),
    download: filters =>
      API.get('/report/accountingDownload', {
        params: filters,
        responseType: 'blob'
      })
  }
}
