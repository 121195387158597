import {
  GET_ORDERS,
  GET_ORDER,
  GET_ORDER_LOGS,
  GET_ORDER_STEPPER,
  GET_ORDER_INTERVENTIONS,
  FIX_ORDER_INTERVENTION
} from '../action-types'

export const initialState = {
  list: [],
  current: {},
  logs: [],
  stepper: {},
  interventions: []
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDERS:
      return { ...state, list: payload }
    case GET_ORDER:
      return { ...state, current: payload }
    case GET_ORDER_LOGS:
      return { ...state, logs: payload }
    case GET_ORDER_STEPPER:
      return { ...state, stepper: payload }
    case GET_ORDER_INTERVENTIONS:
    case FIX_ORDER_INTERVENTION:
      return { ...state, interventions: payload }
    default:
      return state
  }
}
