import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getReceiptTodoOrders } from 'Redux/actions/order-actions'

import OrdersTable from 'Components/OrdersTable'
import { PageTitle } from 'Common/styled'

class ReceiptTodo extends Component {
  static propTypes = {
    getReceiptTodoOrders: PropTypes.func,
    orders: PropTypes.arrayOf(PropTypes.object)
  }
  state = {
    isLoading: false
  }

  componentDidMount() {
    this.getReceiptTodoOrders()
  }
  getReceiptTodoOrders = async () => {
    const { getReceiptTodoOrders } = this.props
    this.setState({ isLoading: true })
    await getReceiptTodoOrders()
    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading } = this.state
    const { history, orders } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'receiptTodo.title'} />
        </PageTitle>
        <OrdersTable
          orders={orders}
          isLoading={isLoading}
          navigateToOrder={id => history.push(`/order/${id}`)}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  orders: state.order.receiptTodo
})
const mapDispatchToProps = {
  getReceiptTodoOrders
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptTodo)
