import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'
import { InputNumber, Checkbox, DatePicker, Input } from 'antd'

import { StyledModal } from './styled'

import { READ_DATE_FORMAT, WRITE_DATE_FORMAT } from 'Common/constants'

const TransferDataModal = props => {
  const { intl, isVisible, data, updateData, saveData, closeModal } = props
  const {
    bankTransferValue,
    hasDeduction,
    accountingDate,
    valueDate,
    cro,
    bankTransferReason
  } = data
  const isSubmitEnabled = data.bankTransferValue && data.cro

  return (
    <StyledModal
      visible={isVisible}
      title={intl.formatMessage({ id: 'order.transferDataModal.title' })}
      okText={intl.formatMessage({ id: 'order.transferDataModal.okText' })}
      okButtonProps={{ disabled: !isSubmitEnabled }}
      onOk={saveData}
      cancelText={intl.formatMessage({ id: 'order.modal.cancelText' })}
      onCancel={closeModal}>
      <InputNumber
        placeholder={intl.formatMessage({
          id: 'order.transferDataModal.placeholder.bankTransferValue'
        })}
        step={0.01}
        value={bankTransferValue}
        onChange={value => updateData('bankTransferValue', value)}
      />
      <Checkbox
        checked={hasDeduction}
        onChange={e => updateData('hasDeduction', e.target.checked)}>
          <FormattedMessage id={'order.transferDataModal.placeholder.hasDeduction'} />
      </Checkbox>
      <DatePicker
        format={READ_DATE_FORMAT}
        value={accountingDate ? moment(accountingDate) : undefined}
        placeholder={intl.formatMessage({
          id: 'order.transferDataModal.placeholder.accountingDate'
        })}
        onChange={date =>
          updateData('accountingDate', date.format(WRITE_DATE_FORMAT))
        }
      />
      <DatePicker
        format={READ_DATE_FORMAT}
        value={valueDate ? moment(valueDate) : undefined}
        placeholder={intl.formatMessage({
          id: 'order.transferDataModal.placeholder.valueDate'
        })}
        onChange={date =>
          updateData('valueDate', date.format(WRITE_DATE_FORMAT))
        }
      />
      <Input
        value={cro}
        placeholder={intl.formatMessage({
          id: 'order.transferDataModal.placeholder.cro'
        })}
        onChange={e => updateData('cro', e.target.value)}
      />
      <Input.TextArea
        value={bankTransferReason}
        placeholder={intl.formatMessage({
          id: 'order.transferDataModal.placeholder.bankTransferReason'
        })}
        onChange={e => updateData('bankTransferReason', e.target.value)}
      />
    </StyledModal>
  )
}
TransferDataModal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  isVisible: PropTypes.bool,
  saveData: PropTypes.func,
  updateData: PropTypes.func
}
export default injectIntl(TransferDataModal)
