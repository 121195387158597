import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Row, Col, Modal, Input, Space } from 'antd'
import { FileTextOutlined, MailOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  getOrder,
  getOrderLogs,
  getOrderStepper,
  getOrderInterventions,
  fixOrderIntervention,
  importOrder,
  savePaymentData,
  sendReceipt,
  createAutomaticReceipt,
  manualReceipt
} from 'Redux/actions/order-actions'

import Loader from 'Components/Loader'
import Card from 'Components/Card'
import Stepper from 'Components/Stepper'
import LogsTable from 'Components/LogsTable'
import InterventionsModal from 'Components/InterventionsModal'
import TransferDataModal from 'Components/TransferDataModal'
import { PageTitle } from 'Common/styled'

import { isMobile, READ_DATE_FORMAT, buttonActionsMap } from 'Common/constants'
import { formatDate } from 'Common/utils'

class Order extends Component {
  static propTypes = {
    createAutomaticReceipt: PropTypes.func,
    fixOrderIntervention: PropTypes.func,
    getOrder: PropTypes.func,
    getOrderInterventions: PropTypes.func,
    getOrderLogs: PropTypes.func,
    getOrderStepper: PropTypes.func,
    importOrder: PropTypes.func,
    interventions: PropTypes.arrayOf(PropTypes.object),
    logs: PropTypes.arrayOf(PropTypes.object),
    manualReceipt: PropTypes.func,
    order: PropTypes.object,
    savePaymentData: PropTypes.func,
    sendReceipt: PropTypes.func,
    stepper: PropTypes.object
  }

  state = {
    isLoading: true,
    isInterventionsModalLoading: false,
    isInterventionsModalVisible: false,
    isTransferDataModalVisible: false,
    transferData: {
      hasDeduction: false
    }
  }

  componentDidMount() {
    const { match, history } = this.props
    const { orderFullNumber } = match.params
    if (orderFullNumber) {
      const [storeId, orderNumber] = orderFullNumber.split('-')
      this.getOrder(+orderNumber, +storeId)
    } else {
      history.push('/')
    }
  }

  getOrder = async (orderNumber, storeId) => {
    const {
      history,
      order,
      getOrder,
      getOrderLogs,
      getOrderStepper,
      importOrder
    } = this.props
    this.setState({ isLoading: true })
    const res = await getOrder(
      orderNumber || order.itemNumber,
      storeId || order.storeId
    )

    if (res.status === 204) return history.push('/')

    await getOrderLogs(res.orderNumber)
    await getOrderStepper(res.orderNumber)
    await importOrder(res.orderNumber, res.storeId, false)
    this.setState({ isLoading: false })
  }

  getOrderInterventions = async () => {
    const { getOrderInterventions } = this.props
    this.setState({ isLoading: true })
    await getOrderInterventions()
    this.setState({ isLoading: false })
    this.openInterventionsModal()
  }

  fixOrderIntervention = async interventionId => {
    const { fixOrderIntervention } = this.props
    this.setState({ isInterventionsModalLoading: true })
    await fixOrderIntervention(interventionId)
    this.setState({ isInterventionsModalLoading: false })
  }

  finishInterventions = async () => {
    await this.fixOrderIntervention()
    this.closeInterventionsModal()
    this.getOrder()
  }

  savePaymentData = async () => {
    const { transferData } = this.state
    const { savePaymentData } = this.props

    this.closeTransferDataModal()
    this.setState({ isLoading: true })
    await savePaymentData(transferData)
    this.getOrder()
  }

  sendReceipt = async () => {
    const { order, sendReceipt } = this.props
    this.setState({ isLoading: true })
    sendReceipt(order.customer)
    this.getOrder()
  }

  createAutomaticReceipt = async () => {
    const { createAutomaticReceipt } = this.props
    this.setState({ isLoading: true })
    await createAutomaticReceipt()
    this.getOrder()
  }

  manualReceipt = async receiptId => {
    const { manualReceipt } = this.props
    this.setState({ isLoading: true })
    await manualReceipt(receiptId)
    this.getOrder()
  }

  navigateToHome = () => {
    const { history } = this.props
    history.goBack()
  }

  updateTransferData = (key, value) =>
    this.setState(prevState => ({
      transferData: { ...prevState.transferData, [key]: value }
    }))

  openModal = (icon, title, content, okCallback, okText) => {
    const { intl } = this.props
    Modal.confirm({
      icon,
      title,
      content,
      okText: okText || intl.formatMessage({ id: 'order.modal.okText' }),
      cancelText: intl.formatMessage({ id: 'order.modal.cancelText' }),
      onOk: okCallback
    })
  }

  openInterventionsModal = () =>
    this.setState({ isInterventionsModalVisible: true })
  closeInterventionsModal = () =>
    this.setState({ isInterventionsModalVisible: false })

  openTransferDataModal = () =>
    this.setState({ isTransferDataModalVisible: true })
  closeTransferDataModal = () =>
    this.setState({
      isTransferDataModalVisible: false,
      transferData: { hasDeduction: false }
    })

  openCustomerModal = () => {
    const { intl } = this.props
    const { customer } = this.props.order
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'order.emailModal.title' })
    const content = (
      <Space direction={'vertical'}>
        <Input value={customer.firstName} />
        <Input value={customer.lastName} />
        <Input value={customer.email} />
        <small>{intl.formatMessage({ id: 'order.emailModal.text' })}</small>
      </Space>
    )
    const okCallback = this.sendReceipt
    this.openModal(icon, title, content, okCallback)
  }

  openReceiptModal = () => {
    const { intl } = this.props
    const icon = <FileTextOutlined />
    const title = intl.formatMessage({ id: 'item.receiptModal.title' })
    const content = (
      <>
        <Input
          id={'receipt'}
          placeholder={intl.formatMessage({
            id: 'order.receiptModal.placeholder'
          })}
        />
      </>
    )
    const okCallback = () =>
      this.manualReceipt(document.getElementById('receipt').value)

    this.openModal(icon, title, content, okCallback)
  }

  renderOrderCard() {
    const { intl, order } = this.props
    const { fullNumber, creationDate, totalAmount } = order
    return (
      <Col key={'order'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          key={'order'}
          title={intl.formatMessage({ id: 'order.card.title.order' })}
          highlight={fullNumber}
          data={[
            intl.formatMessage(
              { id: 'order.card.text.date' },
              { date: formatDate(creationDate, READ_DATE_FORMAT) }
            ),
            intl.formatMessage(
              { id: 'order.card.text.totalAmount' },
              { totalAmount }
            )
          ]}
        />
      </Col>
    )
  }

  renderCustomerCard() {
    const { intl, order } = this.props
    const { customer } = order
    return (
      <Col key={'customer'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          title={intl.formatMessage({ id: 'order.card.title.customer' })}
          highlight={`${customer.firstName} ${customer.lastName}`}
          data={[customer.phoneNumber, customer.email]}
        />
      </Col>
    )
  }

  renderStepper() {
    const { stepper } = this.props
    const buttonActions = {}
    Object.values(buttonActionsMap).map(
      action => (buttonActions[action] = () => this[action]())
    )
    return (
      <Col key={'stepper'} md={{ span: 14 }} xs={{ span: 24 }}>
        <Stepper {...stepper} {...buttonActions} />
      </Col>
    )
  }

  render() {
    const {
      isLoading,
      isInterventionsModalLoading,
      isInterventionsModalVisible,
      isTransferDataModalVisible,
      transferData
    } = this.state
    const { logs, interventions } = this.props
    if (isLoading) return <Loader />

    return (
      <>
        <PageTitle>
          <FormattedMessage id={'order.title'} />
        </PageTitle>
        <Row gutter={[32, 16]}>
          {isMobile
            ? [
                this.renderOrderCard(),
                this.renderCustomerCard(),
                this.renderStepper()
              ]
            : [
                this.renderOrderCard(),
                this.renderStepper(),
                this.renderCustomerCard()
              ]}
        </Row>
        {!isMobile ? <LogsTable logs={logs} /> : null}
        <InterventionsModal
          isLoading={isInterventionsModalLoading}
          isVisible={isInterventionsModalVisible}
          interventions={interventions}
          fixOrderIntervention={this.fixOrderIntervention}
          finishInterventions={this.finishInterventions}
          closeModal={this.closeInterventionsModal}
        />
        <TransferDataModal
          isVisible={isTransferDataModalVisible}
          data={transferData}
          updateData={this.updateTransferData}
          saveData={this.savePaymentData}
          closeModal={this.closeTransferDataModal}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  order: state.order.current,
  logs: state.order.logs,
  stepper: state.order.stepper,
  interventions: state.order.interventions
})
const mapDispatchToProps = {
  getOrder,
  getOrderLogs,
  getOrderStepper,
  getOrderInterventions,
  fixOrderIntervention,
  importOrder,
  savePaymentData,
  sendReceipt,
  createAutomaticReceipt,
  manualReceipt
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Order))
