import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Avatar, List, Modal } from 'antd'
import Loader from 'Components/Loader'
import { CheckOutlined } from '@ant-design/icons'
import { colors } from 'Common/constants'
import Button from 'Components/Button'

const InterventionsModal = props => {
  const {
    intl,
    isLoading,
    isVisible,
    interventions,
    fixOrderIntervention,
    finishInterventions,
    closeModal
  } = props
  return (
    <Modal
      visible={isVisible}
      title={intl.formatMessage({ id: 'order.interventionsModal.title' })}
      onCancel={closeModal}
      onOk={finishInterventions}
      cancelText={intl.formatMessage({ id: 'order.modal.cancelText' })}
      okText={intl.formatMessage({ id: 'order.interventionsModal.okText' })}
      okButtonProps={{
        disabled: interventions.some(int => int.status !== 'FINISHED')
      }}>
      {isLoading ? (
        <Loader height={'50px'} />
      ) : interventions.length > 0 ? (
        <List>
          {interventions.map(int => (
            <List.Item
              key={int.id}
              actions={[
                int.status === 'PENDING' ? (
                  <Button
                    key={'resolve'}
                    type={'link'}
                    onClick={() => fixOrderIntervention(int.id)}>
                    {intl.formatMessage({ id: 'order.button.resolve' })}
                  </Button>
                ) : (
                  <Avatar
                    size={'small'}
                    icon={<CheckOutlined />}
                    style={{ backgroundColor: colors.success }}
                  />
                )
              ]}>
              <span dangerouslySetInnerHTML={{ __html: int.internalLabel }} />
            </List.Item>
          ))}
        </List>
      ) : (
        <FormattedMessage id={'order.interventionsModal.emptyText'} />
      )}
    </Modal>
  )
}
InterventionsModal.propTypes = {
  closeModal: PropTypes.func,
  finishInterventions: PropTypes.func,
  fixOrderIntervention: PropTypes.func,
  interventions: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool
}
export default injectIntl(InterventionsModal)
