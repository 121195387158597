import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import 'moment/locale/it'
import locale from 'antd/es/date-picker/locale/it_IT'

import { Table, Empty, Input, Icon, DatePicker, Popover } from 'antd'

import Loader from 'Components/Loader'
import Button from 'Components/Button'

import {
  colors,
  orderStatuses,
  READ_DATE_FORMAT,
  WRITE_DATE_FORMAT,
} from 'Common/constants'
import { formatDate } from 'Common/utils'

class ReportTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    onFilterReset: PropTypes.func,
    onFilterSearch: PropTypes.func,
  }

  handleSearch = (confirm, dataIndex, value) => {
    const { onFilterSearch } = this.props
    confirm()
    onFilterSearch(dataIndex, value)
  }
  handleReset = (clearFilters, dataIndex) => {
    const { onFilterReset } = this.props
    clearFilters()
    onFilterReset(dataIndex)
  }
  sorter (a, b, dataIndex) {
    if (is(Number, a[dataIndex])) return a[dataIndex] - b[dataIndex]
    if (is(String, a[dataIndex]))
      return a[dataIndex].localeCompare(b[dataIndex])
    return 1
  }
  searchFilterProps (dataIndex, dataType) {
    return {
      filterDropdown: props => {
        const { selectedKeys, confirm, clearFilters } = props
        return (
          <>
            {this.renderSearchInput(dataIndex, dataType, props)}
            <div className={'ant-table-filter-dropdown-btns'}>
              <span
                className={'ant-table-filter-dropdown-link confirm'}
                onClick={() =>
                  this.handleSearch(confirm, dataIndex, selectedKeys[0])
                }>
                {'Cerca'}
              </span>
              <span
                className={'ant-table-filter-dropdown-link clear'}
                onClick={() => this.handleReset(clearFilters, dataIndex)}>
                {'Reset'}
              </span>
            </div>
          </>
        )
      },
      filterIcon: filtered => (
        <Icon
          type={'search'}
          style={{ color: filtered ? colors.main : undefined }}
        />
      ),
      onFilter: (value, row) =>
        row[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      // onFilterDropdownVisibleChange: visible =>
      //   visible && setTimeout(() => this.searchInput.select()),
    }
  }

  renderSearchInput (dataIndex, dataType, filterDropdownProps) {
    const { setSelectedKeys, selectedKeys, confirm } = filterDropdownProps
    const style = { margin: 12, width: 150 }
    switch (dataType) {
      case 'text':
        return (
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={'Cerca'}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(confirm, dataIndex, selectedKeys[0])
            }
            style={style}
          />
        )
      case 'date':
        return (
          <DatePicker
            ref={node => {
              this.searchInput = node
            }}
            format={READ_DATE_FORMAT}
            placeholder={'Scegli data'}
            locale={locale}
            // value={selectedKeys[0]}
            onChange={date =>
              setSelectedKeys(date ? [date.format(WRITE_DATE_FORMAT)] : [])
            }
            style={style}
          />
        )
      default:
        return ''
    }
  }

  render () {
    const { isLoading, data } = this.props
    const columns = [
      {
        key: 'storeId',
        title: 'N. negozio',
        dataIndex: 'storeId',
        sorter: (a, b) => this.sorter(a, b, 'storeId'),
        ...this.searchFilterProps('storeId', 'text'),
      },
      {
        key: 'orderNumber',
        title: 'N. ordine',
        dataIndex: 'orderNumber',
        sorter: (a, b) => this.sorter(a, b, 'orderNumber'),
        ...this.searchFilterProps('orderNumber', 'text'),
      },
      {
        key: 'creationDate',
        title: 'Data ordine',
        dataIndex: 'creationDate',
        render: creationDate => formatDate(creationDate, READ_DATE_FORMAT),
        sorter: (a, b) => this.sorter(a, b, 'creationDate'),
        ...this.searchFilterProps('creationDate', 'date'),
      },
      {
        key: 'totalAmount',
        title: 'Totale ordine',
        dataIndex: 'totalAmount',
        render: totalAmount => `${totalAmount}€`,
        sorter: (a, b) => this.sorter(a, b, 'totalAmount'),
      },
      {
        key: 'paidAmount',
        title: 'Importo versato',
        dataIndex: 'paidAmount',
        render: paidAmount => `${paidAmount}€`,
        sorter: (a, b) => this.sorter(a, b, 'paidAmount'),
      },
      {
        key: 'orderStatus',
        title: 'Stato ordine',
        dataIndex: 'orderStatus',
        render: orderStatus => orderStatuses[orderStatus]
          ? orderStatuses[orderStatus].label
          : orderStatus,

        sorter: (a, b) =>
          orderStatuses[a.orderStatus]
            ? orderStatuses[a.orderStatus].label.localeCompare(
              orderStatuses[b.orderStatus].label
            )
            : a.orderStatus.localeCompare(b.orderStatus),
      },
      {
        key: 'hasDeduction',
        title: 'Detrazione',
        dataIndex: 'hasDeduction',
        render: hasDeduction => (hasDeduction ? 'Sì' : 'No'),
        sorter: (a, b) => this.sorter(a, b, 'hasDeduction'),
      },
      {
        key: 'accountingDate',
        title: 'Data contabile',
        dataIndex: 'accountingDate',
        render: accountingDate => formatDate(accountingDate, READ_DATE_FORMAT),
        sorter: (a, b) => this.sorter(a, b, 'accountingDate'),
        ...this.searchFilterProps('accountingDate', 'date'),
      },
      {
        key: 'valueDate',
        title: 'Data valuta',
        dataIndex: 'valueDate',
        render: valueDate => formatDate(valueDate, READ_DATE_FORMAT),
        sorter: (a, b) => this.sorter(a, b, 'valueDate'),
        ...this.searchFilterProps('valueDate', 'date'),
      },
      {
        key: 'cro',
        title: 'CRO',
        dataIndex: 'cro'
      },
      {
        key: 'bankTransferReason',
        title: 'Causale',
        dataIndex: 'bankTransferReason',
        render: bankTransferReason => (
          <Popover content={bankTransferReason} trigger={'click'}>
            <Button ghost icon={'info'} />
          </Popover>
        ),
      },
      {
        key: 'receiptType',
        title: 'Tipo scontrino',
        dataIndex: 'receiptType',
        sorter: (a, b) => this.sorter(a, b, 'receiptType'),
      },
    ]
    return (
      <Table
        dataSource={data}
        columns={columns}
        rowKey={row => row.Id}
        loading={{
          spinning: isLoading,
          indicator: <Loader />,
        }}
        locale={{
          emptyText: <Empty description={'Nessun dato'} />,
        }}
      />
    )
  }
}
export default ReportTable
