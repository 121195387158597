import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Input } from 'antd'
import { ImportOutlined } from '@ant-design/icons'

import Button from 'Components/Button'
import { ImportWrapper } from './styled'

const ImportOrder = props => {
  const { intl, isLoading, onSubmit } = props
  const [orderNumber, setOrderNumber] = useState()
  return (
    <ImportWrapper>
      <Input
        id={'order-number'}
        maxLength={10}
        placeholder={intl.formatMessage({ id: 'home.placeholder.import' })}
        onChange={e => setOrderNumber(e.target.value)}
        onPressEnter={() => onSubmit(orderNumber)}
      />
      <Button
        icon={<ImportOutlined />}
        loading={isLoading}
        onClick={() => onSubmit(orderNumber)}>
        <span>
          <FormattedMessage id={'home.button.import'} />
        </span>
      </Button>
    </ImportWrapper>
  )
}
ImportOrder.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
}
export default injectIntl(ImportOrder)
