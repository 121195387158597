import {
  GET_ORDERS,
  IMPORT_ORDER,
  GET_ORDER,
  GET_ORDER_LOGS,
  GET_ORDER_STEPPER,
  GET_ORDER_INTERVENTIONS,
  FIX_ORDER_INTERVENTION,
  SAVE_PAYMENT_DATA,
  SEND_RECEIPT,
  CREATE_AUTOMATIC_RECEIPT,
  MANUAL_RECEIPT
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getOrders = type => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.order.list(storeId, type)
    return dispatch({ type: GET_ORDERS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getReceiptTodoOrders = () => async dispatch => {
  try {
    const res = await api.order.receiptTodoList()
    return dispatch({ type: GET_ORDERS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const importOrder = (orderCode, storeId, log) => async dispatch => {
  try {
    const res = await api.order.import(orderCode, storeId, log)
    const { status, data } = res
    dispatch({ type: IMPORT_ORDER })
    return { status, message: data.message, orderFullNumber: data.fullNumber }
  } catch (error) {
    return handleServerError(error)
  }
}
export const getOrder = (number, storeId) => async dispatch => {
  try {
    const res = await api.order.get(number, storeId)
    const { status, data } = res
    dispatch({ type: GET_ORDER, payload: data || {} })
    return {
      status,
      orderNumber: data.itemNumber,
      storeId: data.storeId
    }
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrderLogs = number => async (dispatch, getState) => {
  const { storeId } = getState().order.current
  try {
    const res = await api.order.logs(number, storeId)
    return dispatch({ type: GET_ORDER_LOGS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrderStepper = number => async (dispatch, getState) => {
  const { storeId } = getState().order.current
  try {
    const res = await api.order.stepper(number, storeId)
    return dispatch({ type: GET_ORDER_STEPPER, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getOrderInterventions = () => async (dispatch, getState) => {
  const { itemNumber, storeId } = getState().order.current
  try {
    const res = await api.order.interventions(itemNumber, storeId)
    return dispatch({ type: GET_ORDER_INTERVENTIONS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const fixOrderIntervention =
  interventionId => async (dispatch, getState) => {
    const { itemNumber, storeId } = getState().order.current
    try {
      const res = await api.order.fixIntervention(
        interventionId,
        itemNumber,
        storeId
      )
      return dispatch({ type: FIX_ORDER_INTERVENTION, payload: res.data })
    } catch (error) {
      return handleServerError(error)
    }
  }

export const savePaymentData = paymentData => async (dispatch, getState) => {
  const { itemNumber, storeId } = getState().order.current
  try {
    await api.order.savePaymentData(paymentData, itemNumber, storeId)
    return dispatch({ type: SAVE_PAYMENT_DATA })
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendReceipt = customer => async (dispatch, getState) => {
  const { itemNumber, storeId } = getState().order.current
  const body = { customer }
  debugger
  try {
    await api.order.sendReceipt(body, itemNumber, storeId)
    return dispatch({ type: SEND_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const createAutomaticReceipt = () => async (dispatch, getState) => {
  const { itemNumber, storeId } = getState().order.current
  try {
    await api.order.createAutomaticReceipt(itemNumber, storeId)
    return dispatch({ type: CREATE_AUTOMATIC_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const manualReceipt = receiptId => async (dispatch, getState) => {
  const { itemNumber, storeId } = getState().order.current
  const body = { receiptId }
  try {
    await api.order.manualReceipt(body, itemNumber, storeId)
    return dispatch({ type: MANUAL_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}
