import React from 'react'
import PropTypes from 'prop-types'
import { Card as AntdCard } from 'antd'

import { CardTitle, HighlightText, Text } from './styled'

const Card = props => {
  const { title, highlight, data } = props

  return (
    <AntdCard>
      <CardTitle>{title}</CardTitle>
      <HighlightText>{highlight}</HighlightText>
      {data.map((d, index) => (
        <Text key={index}>{d}</Text>
      ))}
    </AntdCard>
  )
}
Card.propTypes = {
  title: PropTypes.string,
  highlight: PropTypes.string,
  data: PropTypes.array,
}
export default Card
