export const BU = 5
export const APPLICATION = 'ecommerce-banktransfer'
export const ITEMS_TYPE = 'order'

export const isMobile = window.innerWidth < 600

export const READ_DATE_FORMAT = 'DD/MM/YYYY'
export const WRITE_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const HUBPAY_STATUS_IMPORTED = 'IMPORTED'
const HUBPAY_STATUS_FIX_INTERVENTIONS_DONE = 'FIX_INTERVENTIONS_DONE'
const HUBPAY_STATUS_FIX_INTERVENTIONS_FAIL = 'FIX_INTERVENTIONS_FAIL'
const HUBPAY_STATUS_PAYMENT_DONE = 'PAYMENT_DONE'
const HUBPAY_STATUS_PAYMENT_FAIL = 'PAYMENT_FAIL'
const HUBPAY_STATUS_SEND_RECEIPT_DONE = 'SEND_RECEIPT_DONE'
const HUBPAY_STATUS_SEND_RECEIPT_FAIL = 'SEND_RECEIPT_FAIL'
const HUBPAY_STATUS_RECEIPT_PENDING = 'RECEIPT_PENDING'
const HUBPAY_STATUS_RECEIPT_DONE = 'RECEIPT_DONE'
const HUBPAY_STATUS_RECEIPT_FAIL = 'RECEIPT_FAIL'
const HUBPAY_STATUS_COMPLETED = 'COMPLETED'
const HUBPAY_STATUS_CANCELLED = 'CANCELLED'

const HUBPAY_BADGE_STATUS_SUCCESS = 'success'
const HUBPAY_BADGE_STATUS_PROCESSING = 'processing'
const HUBPAY_BADGE_STATUS_ERROR = 'error'

export const hubPayStatusesMap = {
  [HUBPAY_STATUS_IMPORTED]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_FIX_INTERVENTIONS_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_FIX_INTERVENTIONS_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_PAYMENT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_PAYMENT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_SEND_RECEIPT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_SEND_RECEIPT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_RECEIPT_PENDING]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_RECEIPT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_RECEIPT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_COMPLETED]: HUBPAY_BADGE_STATUS_SUCCESS,
  [HUBPAY_STATUS_CANCELLED]: HUBPAY_BADGE_STATUS_ERROR
}

export const orderStatuses = {
  NOT_VALIDATED: { label: 'Non convalidato' },
  CANCELLED: { label: 'Annullato' },
  ORDERED: { label: 'Ordinato' },
  PARTIAL: { label: 'Consegna parziale' },
  PAID: { label: 'Pagato' },
  TO_BE_STORED: { label: 'Da indirizzare' },
  VALIDATED: { label: 'Convalidato' }
}

export const buttonActionsMap = {
  REFRESH: 'getOrder',
  FIX_INTERVENTIONS: 'getOrderInterventions',
  SEND_PAYMENT_INFO: 'openTransferDataModal', //
  SEND_RECEIPT: 'openCustomerModal',
  AUTOMATIC_RECEIPT: 'createAutomaticReceipt',
  MANUAL_RECEIPT: 'openReceiptModal',
  RETURN_TO_LIST: 'navigateToHome'
}
