import styled from 'styled-components'
import { colors } from 'Common/constants'

export const CardTitle = styled.p`
  color: ${colors.grey4};
  text-transform: uppercase;
  margin: 0;
`
export const HighlightText = styled.h2`
  margin: 0;
`
export const Text = styled.p`
  margin: 0;
`
