import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, Empty, Badge } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import Loader from 'Components/Loader'
import Button from 'Components/Button'

import { hubPayStatusesMap, READ_DATE_FORMAT } from 'Common/constants'
import { formatDate, getShortName } from 'Common/utils'

const OrdersTable = props => {
  const { intl, orders, isLoading, navigateToOrder } = props
  const columns = [
    {
      key: 'orderNumber',
      title: intl.formatMessage({ id: 'order.table.column.orderNumber' }),
      dataIndex: 'fullNumber',
      sorter: (a, b) => a.fullNumber.localeCompare(b.fullNumber)
    },
    {
      key: 'totalAmount',
      title: intl.formatMessage({ id: 'order.table.column.totalAmount' }),
      dataIndex: 'totalAmount',
      render: totalAmount => `${totalAmount}€`,
      sorter: (a, b) => a.totalAmount - b.totalAmount
    },
    {
      key: 'createdBy',
      title: intl.formatMessage({ id: 'order.table.column.createdBy' }),
      render: (_, row) =>
        getShortName(row.createdByFirstName, row.createdByLastName),
      sorter: (a, b) =>
        getShortName(a.createdByFirstName, a.createdByLastName).localeCompare(
          getShortName(b.createdByFirstName, b.createdByLastName)
        )
    },
    {
      key: 'date',
      title: intl.formatMessage({ id: 'order.table.column.date' }),
      dataIndex: 'date',
      render: date => formatDate(date, READ_DATE_FORMAT),
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      key: 'hubPayStatus',
      title: intl.formatMessage({ id: 'order.table.column.hubPayStatus' }),
      dataIndex: 'hubPayStatus',
      render: hubPayStatus => (
        <>
          <Badge status={hubPayStatusesMap[hubPayStatus]} />{' '}
          <FormattedMessage id={`hubPayStatus.${hubPayStatus}`} />
        </>
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `hubPayStatus.${a.hubPayStatus}` })
          .localeCompare(
            intl.formatMessage({ id: `hubPayStatus.${b.hubPayStatus}` })
          )
    },
    {
      key: 'orderStatus',
      title: intl.formatMessage({ id: 'order.table.column.orderStatus' }),
      dataIndex: 'status',
      render: orderStatus => (
        <FormattedMessage id={`orderStatus.${orderStatus}`} />
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `orderStatus.${a.status}` })
          .localeCompare(intl.formatMessage({ id: `orderStatus.${b.status}` }))
    },
    {
      key: 'updatedAt',
      title: intl.formatMessage({ id: 'order.table.column.updatedAt' }),
      dataIndex: 'updatedAt',
      render: updatedAt => formatDate(updatedAt, READ_DATE_FORMAT),
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    },
    {
      key: intl.formatMessage({ id: 'order.table.column.action' }),
      title: 'Azione',
      render: (_, row) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => navigateToOrder(row.fullNumber)}
        />
      )
    }
  ]
  return (
    <Table
      dataSource={orders}
      columns={columns}
      rowKey={row => row.Id}
      loading={{
        spinning: isLoading,
        indicator: <Loader />
      }}
      locale={{
        triggerAsc: intl.formatMessage({ id: 'order.table.sortAsc' }),
        triggerDesc: intl.formatMessage({ id: 'order.table.sortDesc' }),
        cancelSort: intl.formatMessage({ id: 'order.table.cancelSort' }),
        emptyText: (
          <Empty
            description={intl.formatMessage({ id: 'order.table.emptyText' })}
          />
        )
      }}
    />
  )
}
OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object)
}
export default injectIntl(OrdersTable)
