import React from 'react'
import PropTypes from 'prop-types'
import * as AntdIcons from '@ant-design/icons'

import Button from 'Components/Button'
import { ActionsRow } from 'Common/styled'
import { Steps, Step, StepIconWrapper, StatusDescription } from './styled'

import { buttonActionsMap } from 'Common/constants'

const Stepper = props => {
  const { activeStep, statusDescription, buttons = [], steps = [] } = props
  return (
    <>
      <Steps current={activeStep - 1} className={'ant-steps-label-vertical'}>
        {steps.map((step, index) => {
          const { icon, color, name } = step
          const StepIcon = AntdIcons[icon]
          return (
            <Step
              key={`step-${index}`}
              icon={<StepIconWrapper icon={<StepIcon />} color={color} />}
              title={name}
            />
          )
        })}
      </Steps>
      <StatusDescription>{statusDescription}</StatusDescription>
      <ActionsRow>
        {buttons.map((button, index) => {
          const { enabled, icon, label, action } = button
          const ButtonIcon = icon ? AntdIcons[icon] : 'span'
          return (
            <Button
              key={`button-${index}`}
              icon={<ButtonIcon />}
              disabled={!enabled}
              onClick={props[buttonActionsMap[action]]}>
              {label}
            </Button>
          )
        })}
      </ActionsRow>
    </>
  )
}
Stepper.propTypes = {
  activeStep: PropTypes.number,
  buttons: PropTypes.array,
  statusDescription: PropTypes.string,
  steps: PropTypes.array,
}
export default Stepper
