import { GET_REPORT, DOWNLOAD_REPORT } from '../action-types'
import api from 'Common/api'
import { handleServerError, handleFileDownload } from 'Common/utils'

export const getReport = () => async dispatch => {
  try {
    const res = await api.report.get()
    return dispatch({ type: GET_REPORT, payload: res.data.sal })
  } catch (error) {
    return handleServerError(error)
  }
}
export const downloadReport = filters => async dispatch => {
  try {
    const res = await api.report.download(filters)
    const { data, headers } = res
    const [, filename] = headers['content-disposition'].split('filename=')
    handleFileDownload(data, filename, headers['content-type'])
    return dispatch({ type: DOWNLOAD_REPORT })
  } catch (error) {
    return handleServerError(error)
  }
}
