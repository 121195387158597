import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
const Page404 = props => (
  <>
    <h1>
      <FormattedMessage id={'app.404.text'} />
    </h1>
    <Link to={'/'}>
      <FormattedMessage id={'app.404.link'} />
    </Link>
  </>
)
export default Page404
