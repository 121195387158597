import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { message } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import { getOrders, importOrder } from 'Redux/actions/order-actions'

import Button from 'Components/Button'
import ImportOrder from 'Components/ImportOrder'
import OrdersTable from 'Components/OrdersTable'
import { PageTitle, ActionsRow } from 'Common/styled'
import { isMobile } from 'Common/constants'

class Home extends Component {
  static propTypes = {
    getOrders: PropTypes.func,
    importOrder: PropTypes.func,
    orders: PropTypes.arrayOf(PropTypes.object),
    storeId: PropTypes.number
  }

  state = {
    isLoading: false
  }

  componentDidMount() {
    this.getOrders()
  }

  getOrders = async () => {
    const { getOrders } = this.props
    this.setState({ isLoading: true })
    await getOrders('active')
    this.setState({ isLoading: false })
  }

  importOrder = async orderCode => {
    const { history, storeId, importOrder } = this.props
    if (orderCode) {
      this.setState({ isLoading: true })

      const res = await importOrder(orderCode, storeId, true)
      debugger
      if (res?.status === 200)
        return history.push(`/order/${res.orderFullNumber}`)
      if (res?.status === 202)
        message.error({ content: res.message, duration: 5 })

      this.setState({ isLoading: false })
    }
  }

  render() {
    const { isLoading } = this.state
    const { history, orders } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
        <ActionsRow>
          <ImportOrder isLoading={isLoading} onSubmit={this.importOrder} />
          {!isMobile ? (
            <Button icon={<ReloadOutlined />} onClick={this.getOrders}>
              {' '}
              <FormattedMessage id={'home.button.reload'} />
            </Button>
          ) : null}
        </ActionsRow>
        {!isMobile ? (
          <OrdersTable
            orders={orders}
            isLoading={isLoading}
            navigateToOrder={fullNumber => history.push(`/order/${fullNumber}`)}
          />
        ) : null}
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.storeId,
  orders: state.order.list
})
const mapDispatchToProps = {
  getOrders,
  importOrder
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
