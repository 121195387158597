import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import order from './order-reducer'

const combinedReducers = combineReducers({
  user,
  app,
  order,
})

export default combinedReducers
