import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { connect } from 'react-redux'

import { getReport, downloadReport } from 'Redux/actions/app-actions'

// import Loader from 'Components/Loader'
import Button from 'Components/Button'
import ReportTable from 'Components/ReportTable'
import { PageTitle, ActionsRow } from 'Common/styled'

class Report extends Component {
  static propTypes = {
    getReport: PropTypes.func,
  }
  state = {
    isLoading: false,
    tableFilters: {},
  }

  componentDidMount() {
    this.getReport()
  }
  getReport = () => {
    const { getReport } = this.props
    this.setState({ isLoading: true })
    getReport().then(() => this.setState({ isLoading: false }))
  }

  downloadReport = () => {
    const { tableFilters } = this.state
    const { downloadReport } = this.props
    this.setState({ isLoading: true })
    downloadReport(tableFilters).then(() => this.setState({ isLoading: false }))
  }

  setTableFilter = (key, value) =>
    this.setState(prevState => ({
      tableFilters: {
        ...prevState.tableFilters,
        [key]: value,
      },
    }))
  resetTableFilter = key =>
    this.setState(prevState => ({
      tableFilters: omit([key], prevState.tableFilters),
    }))

  render() {
    const { isLoading } = this.state
    const { report } = this.props
    // if (isLoading) return <Loader />
    return (
      <>
        <PageTitle>{'Report'}</PageTitle>
        <ActionsRow>
          <Button icon={'download'} onClick={this.downloadReport}>
            {'Scarica report'}
          </Button>
        </ActionsRow>
        <ReportTable
          isLoading={isLoading}
          data={report}
          onFilterSearch={this.setTableFilter}
          onFilterReset={this.resetTableFilter}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  report: state.app.report,
})
const mapDispatchToProps = {
  getReport,
  downloadReport,
}
export default connect(mapStateToProps, mapDispatchToProps)(Report)
