import { GET_REPORT } from '../action-types'

export const initialState = {}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPORT:
      return { ...state, report: payload }

    default:
      return state
  }
}

export default reducer
