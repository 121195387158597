import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import Archive from 'Containers/Archive'
import ReceiptTodo from 'Containers/ReceiptTodo'
import Report from 'Containers/Report'
import Order from 'Containers/Order'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }
  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: '72f1e7fe-a42e-4cf4-aa84-420d5f340982', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.ldap,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  render() {
    const { user } = this.props
    return (
      <WhiteLayout>
        <Header user={user} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
              <Route component={Archive} path={'/archive'} />
              <Route component={ReceiptTodo} path={'/receipt-todo'} />
              <Route component={Report} path={'/report'} />
              <Route component={Order} path={'/order/:orderFullNumber'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(App)
